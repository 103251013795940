/* .video_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .player__wrapper {
    position: relative;
  }
  
  .player {
    border: 2px solid #7b2cbf;
    object-fit: cover;
    padding: 0;
    margin: 0;
  }
  
  h2 {
    color: #7b2cbf;
  } */

  /* .App {
    width: 100%;
    height: 100vh;
  } */

  /* .scrollable-content {
    height: 400px; 
    overflow: scroll;
  } */
  
  /* .dynamic-content {
  flex: 1;
  overflow-y: auto;
  padding-top: 60%;
  padding-bottom: 80px;
} */

.dynamic-content-player {
  flex: 1;
  overflow-y: auto;
  padding-top: 60%;
  /* padding-bottom: 80px; */
}

  .player-component {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    height: 100vh; /* Use full viewport height */
    overflow: hidden; /* Prevent scroll in landscape mode */
  }

  
  .player-container {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden; /* Hide overflow content */
  
    /* height: 100%; */
  }

  
  
  .player-wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    /* width: auto;
    height: auto; */
    width: 100% !important; 
    height: 100% !important;
  }
  
  .video-controller {
    background: rgba(255, 255, 255, 0);
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 5px 0;
  }
  
  .video-controller > div {
    margin: 0 5px;
  }
  
  .volume > img {
    width: 24px;
    height: auto;
  }
  
  .slider {
    width: 100px;
  }
  
  .overlay-player {
    position: absolute;
    top: 25%; /* Adjust to center vertically */
    left: 25%; /* Adjust to center horizontally */
    width: 50%; /* 50% of player width */
    height: 50%; /* 50% of player height */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.0); /* Adjust the opacity as needed */
  }


  .overlay-player-mobile{
    position: absolute;
    top: 8%;
    left: 38%;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 0 0 / 0%);
  }
  
  .skip-button {
    /* background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px; */
    background-color: #00000000;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 10px;
    /* border-radius: 5px; */
    display: flex;
    align-items: center;
    height: 25%;
    margin-left: 30px;
    margin-top: 6rem;
  }
  

  .skip-button-web{
    background-color: #00000000;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    height: 10%;
    margin-left: 5%;
    margin-top: 25%;
  }

  .skip-button-web-1{
    background-color: #00000000;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    height: 10%;
    margin-left: 35%;
    margin-top: 25%;
  }

  .skip-button-web:hover,
.skip-button-web:active {
    background-color: transparent; /* Reset background color to transparent */
    border: none; /* Reset border */
}

.skip-button-web-1:hover,
.skip-button-web-1:active {
    background-color: transparent; /* Reset background color to transparent */
    border: none; /* Reset border */
}

  .skip-button:hover {
    background-color: #00000000;
  }

  .skip-button:active {
    /* Apply styles when the button is clicked */
    background-color: initial;
    border: none; /* Reset background color to initial state */
}

  .back-button {
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 40px; /* Adjust width */
    height: 40px; /* Adjust height */
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0; /* Remove excessive padding */
    border: none; /* Remove border */
    border-radius: 50%; /* Ensure it's a circle */
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 999;
    cursor: pointer;
  }


  .player-container-mobile {
    max-width: 100%; /* Take full width of the viewport */
    overflow-x: hidden; /* Hide horizontal overflow */
    overflow-y: auto; /* Enable vertical scrolling when necessary */
    height: 100vh; /* Take full height of the viewport */
    /* flex-wrap: wrap; */
  }
  
  .movie-item-next {
  flex: 0 0 auto;
    border: none;
    background-color: #353a3f00;
    padding: 5px;
    flex-wrap: wrap;
    display: contents;
  
  }

  .movie-list-next {
    flex: 0 0 auto;
    border: none;
    background-color: #353a3f00;
    padding: 5px;
    flex-wrap: wrap;
    overflow-y: auto; /* Enable vertical scrolling */
    height: auto; /* Set a maximum height for the element */
    }
    
    .loader-mobile {
      width: 100px;
      aspect-ratio: 1;
      border-radius: 50%;
      /* background: #e20788; */
      box-shadow: 0 0 0 0 #7863CF;
      animation: l1 1s infinite;
    }
    @keyframes l1 {
        100% {box-shadow: 0 0 0 30px #0000}
    }